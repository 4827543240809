:root {
  --primary-color: #da1f24;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

.sidebar-expand {
  transition: ease-out 0.4s;
  width: 200px;
}

.sidebar-collapsed {
  width: 70px;
  transition: ease-in 0.4s;
}

.layout-expand {
  margin-left: 70px;
  transition: ease-in 0.4s;
}

.layout-collapsed {
  margin-left: 200px;
  transition: ease-out 0.4s;
}
.data-\[state\=checked\]\:bg-primary[data-state="checked"] {
  background-color: var(--primary-color);
}

@keyframes sidebar {
  from {
    width: 0;
  }
  to {
    width: 250px;
  }
}
