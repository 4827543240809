.data-table {
  border-collapse: separate;
  border-spacing: 0 12px;
  /* padding: 6px; */

  thead,
  tbody > tr {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
  }

  thead > tr > th {
    background-color: #D1D1D1;
    color: black;
    font-size: 14px;
    height: 36px;
    font-weight: bold;
    border-bottom: solid px rgba(0, 0, 0, 0.15);
    border-top: solid 1px rgba(0, 0, 0, 0.15);
  }

  thead > tr > th:first-child {
    border-radius: 10px 0px 0px 10px;
    border-left: solid 1px rgba(0, 0, 0, 0.15);
  }

  thead > tr > th:last-child {
    border-radius: 0px 10px 10px 0px;
    border-right: solid 1px rgba(0, 0, 0, 0.15);
  }

  tbody > tr > td {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    padding: 10px 16px;
    font-weight: 500;
    border-bottom: solid px rgba(0, 0, 0, 0.15);
    border-top: solid 1px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  tbody > tr > td:first-child {
    border-radius: 10px 0px 0px 10px;
    border-left: solid 1px rgba(0, 0, 0, 0.15);
  }

  tbody > tr > td:last-child {
    border-radius: 0px 10px 10px 0px;
    border-right: solid 1px rgba(0, 0, 0, 0.15);
  }
}
